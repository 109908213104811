export const Environment = {
  firebaseConfig: {
    apiKey: "AIzaSyA6KlSt8IQBSm7TiDszs29bhKaVev-e1Tw",
    authDomain: "mdm-qcrt-demo-1.firebaseapp.com",
    projectId: "mdm-qcrt-demo-1",
    storageBucket: "mdm-qcrt-demo-1.appspot.com",
    messagingSenderId: "1055119004226",
    appId: "1:1055119004226:web:1d17187e816f794b5713db"
  },
  region: "clinton",
  gcfUrl: "https://webcam.carifenzel.cf/stream/streamapi.php",
  authUser: "gcfuser",
  clientCode: "D*ll%sD0p3Dude"
}
//"https://us-central1-mdm-qcrt-demo-1.cloudfunctions.net/livescans/resetcams