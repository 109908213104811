export const fakeLiveScan =  [
  [
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi368039740.jpg",
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 250.9,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "camanche",
      liveEvents: [
        {camanche: 1645582322}
      ],
      liveInitLat: 41.786653333333334,
      liveInitLon: -90.24742,
      liveInitTS: 1645582281,
      liveIsLocal: false,
      liveLastLat: 41.786665,
      liveLastLon: -90.24739333333333,
      liveLastTS: 1671933613,
      liveLength: null,
      liveLocation: "In Camanche Harbor",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Artco Innovation",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 368039740,
      liveWidth: null,
      type: "Towing" 
    },
           
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366970820.jpg",
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 32.2,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "m512ua",
      liveEvents: [
        {m512ua: 1645582285}
      ],
      liveInitLat: 41.78609333333333,
      liveInitLon: -90.24452,
      liveInitTS: 1645582264,
      liveIsLocal: true,
      liveLastLat: 41.78613166666667,
      liveLastLon: -90.24451,
      liveLastTS: 1671933613,
      liveLength: null,
      liveLocation: "At M-512 by Camanche marina 6 miles below Clinton drawbridge",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Huge Friggin' Boat",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 366970820,
      liveWidth: null,
      type: "Passenger" 
    },
    
    {
    imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366986460.jpg",
    liveCallSign: null,
    liveRegion: "clinton",
    liveCourse: 331.5,
    liveDirection: "downriver",
    liveDraft: null,
    liveEvent: "camanche",
    liveEvents:[
      {camanche: 1645582293}
    ],
    liveInitLat: 41.79203666666667,
    liveInitLon: -90.24426666666666,
    liveInitTS: 1645582271,
    liveIsLocal: false,
    liveLastLat: 41.79204333333333,
    liveLastLon: -90.24426333333334,
    liveLastTS: 1671933613,
    liveLength: null,
    liveLocation: "In Camanche Harbor",
    liveMarkerAlphaTS: null,
    liveMarkerAlphaWasReached: false,
    liveMarkerBravoTS: null,
    liveMarkerBravoWasReached: false,
    liveMarkerCharlieTS: null,
    liveMarkerCharlieWasReached: false,
    liveMarkerDeltaTS: null,
    liveMarkerDeltaWasReached: false,
    liveName: "Lil Charley",
    livePassageWasSaved: false,
    liveSpeed: 0,
    liveVesselID: 366986460,
    liveWidth: null,
    type: "Towing"
    }
  ],

  [
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi368039740.jpg",
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 250.9,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "camanche",
      liveEvents: [
        {camanche: 1645582322}
      ],
      liveInitLat: 41.786653333333334,
      liveInitLon: -90.24742,
      liveInitTS: 1645582281,
      liveIsLocal: false,
      liveLastLat: 41.786665,
      liveLastLon: -90.24739333333333,
      liveLastTS: 1671933793,
      liveLength: null,
      liveLocation: "In Camanche Harbor",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Artco Innovation",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 368039740,
      liveWidth: null,
      type: "Towing" 
    },
           
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366970820.jpg",
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 32.2,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "m512ua",
      liveEvents: [
        {m512ua: 1645582285}
      ],
      liveInitLat: 41.78609333333333,
      liveInitLon: -90.24452,
      liveInitTS: 1645582264,
      liveIsLocal: true,
      liveLastLat: 41.78613166666667,
      liveLastLon: -90.24451,
      liveLastTS: 1671933793,
      liveLength: null,
      liveLocation: "At M-512 by Camanche marina 6 miles below Clinton drawbridge",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Huge Friggin' Boat",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 366970820,
      liveWidth: null,
      type: "Passenger" 
    },
    
    {
    imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366986460.jpg",
    liveCallSign: null,
    liveRegion: "clinton",
    liveCourse: 331.5,
    liveDirection: "downriver",
    liveDraft: null,
    liveEvent: "camanche",
    liveEvents:[
      {camanche: 1645582293}
    ],
    liveInitLat: 41.79203666666667,
    liveInitLon: -90.24426666666666,
    liveInitTS: 1645582271,
    liveIsLocal: false,
    liveLastLat: 41.79204333333333,
    liveLastLon: -90.24426333333334,
    liveLastTS: 1671933793,
    liveLength: null,
    liveLocation: "In Camanche Harbor",
    liveMarkerAlphaTS: null,
    liveMarkerAlphaWasReached: false,
    liveMarkerBravoTS: null,
    liveMarkerBravoWasReached: false,
    liveMarkerCharlieTS: null,
    liveMarkerCharlieWasReached: false,
    liveMarkerDeltaTS: null,
    liveMarkerDeltaWasReached: false,
    liveName: "Lil Charley",
    livePassageWasSaved: false,
    liveSpeed: 0,
    liveVesselID: 366986460,
    liveWidth: null,
    type: "Towing"
    }
  ],

  [
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi368039740.jpg",
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 250.9,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "camanche",
      liveEvents: [
        {camanche: 1645582322}
      ],
      liveInitLat: 41.786653333333334,
      liveInitLon: -90.24742,
      liveInitTS: 1645582281,
      liveIsLocal: false,
      liveLastLat: 41.786665,
      liveLastLon: -90.24739333333333,
      liveLastTS: 1671934153,
      liveLength: null,
      liveLocation: "In Camanche Harbor",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Artco Innovation",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 368039740,
      liveWidth: null,
      type: "Towing" 
    },
           
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366970820.jpg",
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 32.2,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "m512ua",
      liveEvents: [
        {m512ua: 1645582285}
      ],
      liveInitLat: 41.78609333333333,
      liveInitLon: -90.24452,
      liveInitTS: 1645582264,
      liveIsLocal: true,
      liveLastLat: 41.78613166666667,
      liveLastLon: -90.24451,
      liveLastTS: 1671934153,
      liveLength: null,
      liveLocation: "At M-512 by Camanche marina 6 miles below Clinton drawbridge",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Huge Friggin' Boat",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 366970820,
      liveWidth: null,
      type: "Passenger" 
    },
    
    {
    imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366986460.jpg",
    liveCallSign: null,
    liveRegion: "clinton",
    liveCourse: 331.5,
    liveDirection: "downriver",
    liveDraft: null,
    liveEvent: "camanche",
    liveEvents:[
      {camanche: 1645582293}
    ],
    liveInitLat: 41.79203666666667,
    liveInitLon: -90.24426666666666,
    liveInitTS: 1645582271,
    liveIsLocal: false,
    liveLastLat: 41.79204333333333,
    liveLastLon: -90.24426333333334,
    liveLastTS: 1671934153,
    liveLength: null,
    liveLocation: "In Camanche Harbor",
    liveMarkerAlphaTS: null,
    liveMarkerAlphaWasReached: false,
    liveMarkerBravoTS: null,
    liveMarkerBravoWasReached: false,
    liveMarkerCharlieTS: null,
    liveMarkerCharlieWasReached: false,
    liveMarkerDeltaTS: null,
    liveMarkerDeltaWasReached: false,
    liveName: "Lil Charley",
    livePassageWasSaved: false,
    liveSpeed: 0,
    liveVesselID: 366986460,
    liveWidth: null,
    type: "Towing"
    }
  ],

  [
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi368039740.jpg",
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 250.9,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "camanche",
      liveEvents: [
        {camanche: 1645582322}
      ],
      liveInitLat: 41.786653333333334,
      liveInitLon: -90.24742,
      liveInitTS: 1645582281,
      liveIsLocal: false,
      liveLastLat: 41.786665,
      liveLastLon: -90.24739333333333,
      liveLastTS: 1671934333,
      liveLength: null,
      liveLocation: "In Camanche Harbor",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Artco Innovation",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 368039740,
      liveWidth: null,
      type: "Towing" 
    },
           
    {
      imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366970820.jpg",
      inCameraRange: true,
      liveCallSign: null,
      liveRegion: "clinton",
      liveCourse: 32.2,
      liveDirection: "upriver",
      liveDraft: null,
      liveEvent: "m512ua",
      liveEvents: [
        {m512ua: 1645582285}
      ],
      liveInitLat: 41.78609333333333,
      liveInitLon: -90.24452,
      liveInitTS: 1645582264,
      liveIsLocal: true,
      liveLastLat: 41.78613166666667,
      liveLastLon: -90.24451,
      liveLastTS: 1671934333,
      liveLength: null,
      liveLocation: "At M-512 by Camanche marina 6 miles below Clinton drawbridge",
      liveMarkerAlphaTS: null,
      liveMarkerAlphaWasReached: false,
      liveMarkerBravoTS: null,
      liveMarkerBravoWasReached: false,
      liveMarkerCharlieTS: null,
      liveMarkerCharlieWasReached: false,
      liveMarkerDeltaTS: null,
      liveMarkerDeltaWasReached: false,
      liveName: "Huge Friggin' Boat",
      livePassageWasSaved: false,
      liveSpeed: 0,
      liveVesselID: 366970820,
      liveWidth: null,
      type: "Passenger" 
    },
    
    {
    imageUrl: "https://storage.googleapis.com/www.clintonrivertraffic.com/images/vessels/mmsi366986460.jpg",
    liveCallSign: null,
    liveRegion: "clinton",
    liveCourse: 331.5,
    liveDirection: "downriver",
    liveDraft: null,
    liveEvent: "camanche",
    liveEvents:[
      {camanche: 1645582293}
    ],
    liveInitLat: 41.79203666666667,
    liveInitLon: -90.24426666666666,
    liveInitTS: 1645582271,
    liveIsLocal: false,
    liveLastLat: 41.79204333333333,
    liveLastLon: -90.24426333333334,
    liveLastTS: 1671934333,
    liveLength: null,
    liveLocation: "In Camanche Harbor",
    liveMarkerAlphaTS: null,
    liveMarkerAlphaWasReached: false,
    liveMarkerBravoTS: null,
    liveMarkerBravoWasReached: false,
    liveMarkerCharlieTS: null,
    liveMarkerCharlieWasReached: false,
    liveMarkerDeltaTS: null,
    liveMarkerDeltaWasReached: false,
    liveName: "Lil Charley",
    livePassageWasSaved: false,
    liveSpeed: 0,
    liveVesselID: 366986460,
    liveWidth: null,
    type: "Towing"
    }
  ]
   
    
    
   
]